import styled from 'styled-components';
import { media } from '../utils/styles';

export default styled.div`
  padding: 50px 15px;
  margin: ${props => props.header ? '20px auto 0' : '0 auto'};
  text-align: ${props => props.centerText ? 'center': 'left'};

  ${media.tablet`
    max-width: 750px;
    margin: ${props => props.header ? '70px auto 0' : '0 auto'};
  `}

  ${media.smallDesktop`
    max-width: 900px;
  `}

  ${media.desktop`
    max-width: 970px;
  `}

  ${media.giant`
    max-width: 1183px;
  `}
`;
