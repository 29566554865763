import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import TagTemplate from '../components/TagTemplate';

export default ({
  pageContext: { tag: tagName },
  data: {
    allContentfulBlogPost: { edges: articles }
  },
  location: { href }
}) => (
  <Layout canonicalUrl={href}>
    <Seo title={`Tag: ${tagName}`} url={href} description={`Tag: ${tagName}`} />
    <TagTemplate articles={articles} tagName={tagName} />
  </Layout>
);

export const tagQuery = graphql`
  query TagQuery($tag: String!) {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }, filter: { node_locale: { eq: "en" }, featuredImage: { id: { ne: null } }, displayOnBlog: { eq: true }, tags: { name: { eq: $tag } } }) {
      edges {
        node {
          id
          title
          slug
          description {
            id
            description
          }
          description {
            childMarkdownRemark {
              excerpt(pruneLength: 120)
            }
          }
          publishDate
          tags {
            name
          }
          featuredImage {
            title
            fluid(maxWidth: 400) {
              ...GatsbyContentfulFluid
            }
          }
          author {
            id
            name
            biography {
              id
              biography
            }
          }
        }
      }
    }
  }
`;
