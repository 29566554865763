import React from 'react';
import styled from 'styled-components';
import { media } from '../../utils/styles';
import Img from 'gatsby-image';
import Link from 'gatsby-link';
import dayjs from 'dayjs';

const Article = styled.div`
  padding: 10px;

  ${media.tablet`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 50%;
    padding: 0 15px 30px;
  `}

  ${media.smallDesktop`
    width: 33.33%;
  `}
`;

const StyledImage = styled(Img)`
  width: 100%;
  height: 250px;
  background-image: url(${props => props.src});
  background-size: cover;

  > img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
  }

  > img:hover {
    transition: all .3s ease !important;
    transform: scale(1.05,1.05);
    opacity: 0.5;
  }
`;

const ArticleDate = styled.div`
  font: 400 10px/22px ${props => props.theme.headerFontFamily};
  color: #656565;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

const BlogTitle = styled(Link)`
  font: 600 16px/23px ${props => props.theme.headerFontFamily};
  text-transform: capitalize;
  letter-spacing: 0;
  color: ${props => props.theme.primary};
  padding: 20px 0 10px;
  text-decoration: none;
  display: block;
  transition: color 0.2s ease;

  &:hover {
    color: #575757;
  }
`;

const Separator = styled.div`
  width: 30px;
  height: 2px;
  background-color: ${props => props.theme.primary};
  margin: 15px 0;
`;

const Description = styled.div`
  width: 90%;
  font: 300 15px/23px ${props => props.theme.sansSerifFontFamily};
  color: #656565;
`;

export default ({ article }) => (
  <Article>
    <Link to={`/articles/${article.slug}`}>
      <StyledImage alt={article.featuredImage.title} fluid={article.featuredImage.fluid} />
    </Link>
    <BlogTitle to={`/articles/${article.slug}`}>{article.title}</BlogTitle>
    <ArticleDate>
      {`Written by ${article.author !== null ? article.author.name : 'Nubabi'}`}
      <br />
      {`${dayjs(article.publishDate).format('DD MMMM YYYY')}`}
    </ArticleDate>
    <Description>
      {article.description.childMarkdownRemark.excerpt}
    </Description>
    <Separator />
  </Article>
);
